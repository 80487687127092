import { useMicrositeCartSystem } from 'modules/custom/microsite/useCartSystem'
import React, { useEffect, useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { currency } from 'utils/formatNumber'

import ChangeCateringModal from './ChangeCateringModal'
import DateCart from './DateCart'
import MenuList from './MenuList'

const CartResponsive = ({ partner }) => {
  const navigate = useNavigate()
  const {
    cart,
    grandTotal,
    setDateParams,
    calculateTotal,
    addProduct,
    updateQuantity,
    removeProduct,
    changeTimeCart,
    changeDateCart,
  } = useMicrositeCartSystem()
  const [quantity, setQuantity] = useState(0)
  const [showChangeCateringModal, setShowChangeCateringModal] = useState(false)
  const [selectedCatering, setSelectedCatering] = useState('')
  const token = localStorage.getItem('token')

  const addMenu = (time, date) => {
    setDateParams(date)
    sessionStorage.setItem('time', JSON.stringify(time))
    sessionStorage.setItem('date', JSON.stringify(date))
    navigate(`/microsite?token=${token}`)
  }

  const handleSelectCatering = (name) => {
    setSelectedCatering(name)
  }

  useEffect(() => {
    calculateTotal(cart)
  }, [])
  return (
    <React.Fragment>
      <h5 className="text-lg -mb-2 font-semibold">Total Pembayaran</h5>
      <div className="">
        <h4 className="my-4 text-2xl text-primary font-semibold">
          {currency(grandTotal)}
        </h4>
      </div>
      <div className="flex flex-col py-3 border-t border-b">
        <div className="text-xs text-gray-400 mb-2">Nama Mitra</div>
        <h6 className="text-lg">{selectedCatering || partner?.name}</h6>
        <div className="text-gray-400 text-xs">
          Ingin ganti catering?{' '}
          <span
            className="text-primary cursor-pointer"
            onClick={() => setShowChangeCateringModal(true)}
          >
            Klik di sini
          </span>
        </div>
      </div>
      {cart.map((data, key) => {
        {
          {
            data
          }
        }
        return (
          <div
            key={key}
            className="first:border-t border-t-0 py-2 border-b last:border-b-0"
          >
            <div className="py-4">
              <DateCart
                cart={cart}
                date={data.date}
                time={data.shipping_time}
                qty={data.qty_total}
                shipping={data.shipping_cost}
                changeTimeCart={changeTimeCart}
                changeDateCart={changeDateCart}
              />
            </div>
            <div className="flex justify-between py-2 gap-2 text-sm text-gray-400">
              <div>Nama</div>
              <div>Harga</div>
            </div>
            <div className="flex flex-col py-2 gap-2">
              {data.menus.map((menu, key) => {
                let fulfilled = false
                return (
                  <MenuList
                    key={key}
                    qty={menu.qty}
                    name={menu.name}
                    qty_unit_name={menu.qty_unit_name}
                    free_shipping_cost={menu.free_shipping_cost}
                    available={menu.available}
                    max_order={menu.max_order}
                    min_order={menu.min_order}
                    disabled_up={menu.disabled_up}
                    add={addMenu}
                    price={menu.price}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    fulfilled={fulfilled}
                    addProduct={() =>
                      addProduct(menu, data.date, data.shipping_time)
                    }
                    updateQuantity={() =>
                      updateQuantity(
                        menu,
                        data.date,
                        data.shipping_time,
                        quantity
                      )
                    }
                    removeProduct={() =>
                      removeProduct(menu, data.date, data.shipping_time)
                    }
                  />
                )
              })}
            </div>
            <button
              onClick={() => addMenu(data.shipping_time, data.date)}
              className="flex w-full border-2 py-1 justify-center gap-1 items-center text-primary rounded-full border-primary font-semibold text-sm mt-4 mb-4"
            >
              <BiPlus /> Tambah Pesanan
            </button>
            <div className="flex justify-between py-2 gap-2 text-sm font-medium">
              <div className="">Ongkos Kirim</div>
              <div className=" text-end ">{currency(data.shipping_cost)}</div>
            </div>
          </div>
        )
      })}

      <ChangeCateringModal
        showModal={showChangeCateringModal}
        setShowModal={setShowChangeCateringModal}
        onSelectCatering={handleSelectCatering}
      />
    </React.Fragment>
  )
}

export default CartResponsive
