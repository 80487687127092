import React from 'react'
import { HiCheck } from 'react-icons/hi'
import { IoChevronDown } from 'react-icons/io5'

const Dropdown = ({ user, setUser }) => {
  const [open, setOpen] = React.useState(false)

  const checkActive = (user, data) => {
    return user.payment_bank === data ? 'text-primary' : ''
  }

  const handleVA = (data) => {
    setUser({ ...user, payment_bank: data })
    setOpen(false)
  }

  const banks = [
    { id: 'BCA', label: 'BCA' },
    { id: 'BNI', label: 'BNI' },
    { id: 'MANDIRI', label: 'Mandiri' },
    { id: 'BRI', label: 'BRI' },
  ]

  return (
    <div className="relative z-[99]">
      <div
        onClick={() => setOpen(!open)}
        className="flex justify-between cursor-pointer px-5 text-sm items-center w-full py-2"
      >
        <p>
          {user.payment_bank !== 'no account'
            ? user.payment_bank
            : 'Silahkan pilih'}
        </p>
        <IoChevronDown
          className={`text-primary w-5 h-5 transition-all duration-500 ${
            open ? 'rotate-180' : 'rotate-0'
          }`}
        />
      </div>
      {open && (
        <section className="bg-white absolute w-full shadow-search-homepage rounded-lg top-14 bottom-auto">
          <div className="relative z-40 w-full">
            {banks.map((bank) => (
              <div
                key={bank.id}
                onClick={() => handleVA(bank.id)}
                className={`flex justify-between cursor-pointer px-5 py-2 hover:text-primary hover:font-semibold w-full ${checkActive(
                  user,
                  bank.id
                )}`}
              >
                <p>{bank.label}</p>
                {user.payment_bank === bank.id && (
                  <HiCheck className="text-primary" />
                )}
              </div>
            ))}
          </div>
          <div
            onClick={() => setOpen(false)}
            className="fixed inset-0 z-0 w-screen h-screen"
          />
        </section>
      )}
    </div>
  )
}

export default Dropdown
