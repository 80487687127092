import Input from 'components/forms/Input'
import ModalMaps from 'components/modal/ModalMaps'
import guestIcon from 'images/icons/guest.svg'
import phoneIcon from 'images/icons/phone.svg'
import { useGoogleMapHook } from 'modules/custom/useGoogleMapHook'
import React, { useEffect, useState } from 'react'

import CustomForm from '../../../components/forms/CustomForm'
import RadioButton from './fragments/RadioButton'

const RecipientDetail = ({
  setNama,
  setPhone,
  setDeliver,
  dataUser,
  setDataUser,
  userProfile,
  errorName,
  errorPhone,
}) => {
  const [location] = useState(JSON.parse(localStorage.getItem('data-user')))
  const [userData] = useState(JSON.parse(localStorage.getItem('data-user')))
  // const [partnerID] = useState(JSON.parse(localStorage.getItem('partner')))
  const [enabled, setEnabled] = useState(false)

  const {
    showModal,
    setShowModal,
    setLatLong,
    place,
    setPlace,
    setURL,
    setRegion,
    isPartnerExist,
    setIsPartnerExist,
    handleSubmitForm,
    saveLocationMutation,
  } = useGoogleMapHook()

  const handleToggle = () => {
    if (enabled) {
      // Clear the inputs when disabling the toggle
      setDataUser({
        ...dataUser,
        recipient_name: '',
        recipient_phone: '',
      })
      setEnabled(false)
    } else {
      // Fill with user profile data when enabling
      setDataUser({
        ...dataUser,
        recipient_name: userProfile?.name,
        recipient_phone: userProfile?.phone,
      })
      setEnabled(true)
    }
  }

  useEffect(() => {
    if (
      dataUser.recipient_name === userProfile?.name &&
      dataUser.recipient_phone === userProfile?.phone
    ) {
      setEnabled(true)
    } else {
      setEnabled(false)
    }
    localStorage.setItem('data-user', JSON.stringify(dataUser))
  }, [dataUser])

  useEffect(() => {
    setDataUser({
      ...dataUser,
      delivery_address: location.delivery_address,
      lat: location.lat,
      long: location.long,
    })
  }, [location])

  return (
    <CustomForm
      title="Detail Penerima"
      titleSide
      enabled={enabled}
      setSameValue={handleToggle}
    >
      <div className="flex flex-col gap-7 pt-4">
        <div className="flex flex-col gap-7 pb-7">
          <div className="py-1 flex gap-2">
            <img src={guestIcon} alt="guest" className="h-6" />
            <div className="flex w-full flex-col">
              <Input
                type="text"
                name="Nama"
                label="Nama Penerima"
                value={dataUser.recipient_name}
                error={errorName}
                onChange={(e) => {
                  setNama(e.target.value)
                  setDataUser({ ...dataUser, recipient_name: e.target.value })
                }}
              />
            </div>
          </div>
          <div className="py-1 flex gap-2">
            <img src={phoneIcon} alt="phone" className="h-6" />
            <div className="flex w-full flex-col">
              <Input
                type="number"
                name="Nomor"
                label="Nomor Handphone Penerima"
                value={dataUser.recipient_phone}
                error={errorPhone}
                onChange={(e) => {
                  setPhone(e.target.value)
                  setDataUser({ ...dataUser, recipient_phone: e.target.value })
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col -mt-2 gap-5">
          <p className="text-sm">Pemesanan dikirim ke</p>
          <div className="flex flex-col lg:flex-row gap-7">
            <div className="grid grid-cols-2 gap-7 w-full lg:w-fit">
              <RadioButton
                name="lokasi"
                value="Kantor"
                checked={
                  (dataUser.delivery_to ?? userProfile?.data?.delivery_to) ==
                  'Kantor'
                }
                onChange={() => {
                  setDeliver('Kantor')
                  setDataUser({ ...dataUser, delivery_to: 'Kantor' })
                }}
                className="col-span-1"
                buttonClick={() => {
                  setDeliver('Kantor')
                  setDataUser({ ...dataUser, delivery_to: 'Kantor' })
                }}
              />
              <RadioButton
                name="lokasi"
                value="Rumah"
                checked={
                  (dataUser.delivery_to ?? userProfile?.data?.delivery_to) ==
                  'Rumah'
                }
                onChange={() => {
                  setDeliver('Rumah')
                  setDataUser({ ...dataUser, delivery_to: 'Rumah' })
                }}
                className="col-span-1"
                buttonClick={() => {
                  setDeliver('Rumah')
                  setDataUser({ ...dataUser, delivery_to: 'Rumah' })
                }}
              />
            </div>
            <div className="grid grid-cols-2 gap-7 w-full lg:w-fit">
              <RadioButton
                name="lokasi"
                value="Apartemen"
                checked={
                  (dataUser.delivery_to ?? userProfile?.data?.delivery_to) ==
                  'Apartemen'
                }
                onChange={() => {
                  setDeliver('Apartemen')
                  setDataUser({ ...dataUser, delivery_to: 'Apartemen' })
                }}
                className="col-span-1 md:col-span-2"
                buttonClick={() => {
                  setDeliver('Apartemen')
                  setDataUser({ ...dataUser, delivery_to: 'Apartemen' })
                }}
              />
              <RadioButton
                name="lokasi"
                value="Lokasi Acara"
                checked={
                  (dataUser.delivery_to ?? userProfile?.data?.delivery_to) ==
                  'Lokasi Acara'
                }
                onChange={() => {
                  setDeliver('Lokasi Acara')
                  setDataUser({ ...dataUser, delivery_to: 'Lokasi Acara' })
                }}
                className="col-span-1 md:col-span-2"
                buttonClick={() => {
                  setDeliver('Lokasi Acara')
                  setDataUser({ ...dataUser, delivery_to: 'Lokasi Acara' })
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm">Alamat Pengiriman</p>
          <div className="text-sm">
            <div className="">{userData.delivery_address}</div>
          </div>
        </div>
        <div className="w-full h-full rounded-2xl ">
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${userData.lat},${userData.long}&zoom=15&scale=1&size=700x250&markers=color:red|${userData.lat},${userData.long}&key=${process.env.REACT_APP_API_KEY_GMAPS}`}
            alt="lokasi pengiriman"
            className="rounded-xl"
          />
        </div>
      </div>

      {/* MODAL MAPS */}
      <ModalMaps
        showModal={showModal}
        setShowModal={setShowModal}
        setLatLong={setLatLong}
        setPlace={setPlace}
        place={place}
        setURL={setURL}
        setRegion={setRegion}
        isPartnerExist={isPartnerExist}
        setIsPartnerExist={setIsPartnerExist}
        handleSubmitForm={handleSubmitForm}
        isLoadingSave={saveLocationMutation.isLoading}
      />
    </CustomForm>
  )
}

export default RecipientDetail
