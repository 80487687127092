/* eslint-disable no-unused-vars */
import Loading from 'components/layouts/fragments/Loading'
import { useMicrositeCartSystem } from 'modules/custom/microsite/useCartSystem'
import { useOrder } from 'modules/custom/microsite/useOrder'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import CartDetail from './components/CartDetail'
import CustomerDetail from './components/CustomerDetail'
import CartResponsive from './components/fragments/CartResponsive'
import PaymentDetail from './components/PaymentDetail'
import RecipientDetail from './components/RecipientDetail'
import RequestForm from './components/RequestForm'
import MicrositePaymentLayout from 'components/layouts/MicrositePaymentLayout'

const MicrositePaymentInfo = () => {
  const navigate = useNavigate()
  const {
    nama,
    setNama,
    phone,
    setPhone,
    deliver,
    setDeliver,
    dataUser,
    setDataUser,
  } = useOrder()
  const {
    cart,
    grandTotal,
    changeDateCart,
    changeTimeCart,
    calculateTotal,
    addProduct,
    updateQuantity,
    removeProduct,
    setDateParams,
  } = useMicrositeCartSystem()

  const [errorMessage] = useState(<p className="text-end">Wajib diisi</p>)
  const [errorName, setErrorName] = useState(false)
  const [errorPhone, setErrorPhone] = useState(false)
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorPayment, setErrorPayment] = useState(false)
  const [error, setError] = useState(false)

  const [partner] = useState(JSON.parse(localStorage.getItem('partner')))
  const [userProfile] = useState(
    JSON.parse(localStorage.getItem('wa-customer'))
  )

  const validators = {
    phoneRegex: /^(\+62|08)[0-9]{8,12}$/,
    lettersRegex: /^[a-zA-Z\s-]+$/,
    emailRegex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/,
  }

  // handleSubmit
  const handleSubmit = () => {
    setErrorName(false)
    setErrorPhone(false)
    setErrorEmail(false)
    setErrorPayment(false)
    setError(false)

    if (!dataUser.recipient_name) {
      setErrorName(errorMessage)
      setError(true)
      return
    }
    if (!validators.lettersRegex.test(dataUser.recipient_name)) {
      setErrorName(
        <p>Nama tidak valid. Nama tidak boleh mengandung unsur angka.</p>
      )
      setError(true)
      return
    }
    if (!dataUser.recipient_phone) {
      setErrorPhone(errorMessage)
      setError(true)
      return
    }
    if (!validators.phoneRegex.test(dataUser.recipient_phone)) {
      setErrorPhone(
        <p>
          Harap masukkan format nomor yang benar dan angka terdiri dari 10-12
          digit.
        </p>
      )
      setError(true)
      return
    }
    if (dataUser.pic_email) {
      if (!validators.emailRegex.test(dataUser.pic_email)) {
        setErrorEmail(<p>Harap masukkan format email yang benar.</p>)
        setError(true)
        return
      }
    }
    if (
      !dataUser.payment_type ||
      (dataUser.paymnet_type && dataUser.payment_bank === 'no account')
    ) {
      setErrorPayment(true)
      return
    }
    if (
      !dataUser.recipient_name ||
      !dataUser.recipient_phone ||
      !dataUser.payment_type ||
      dataUser.payment_bank === 'no account'
    ) {
      setError(true)
      return
    } else {
      navigate('/ms-payment-confirmation')
    }
  }

  // delete
  useEffect(() => {
    calculateTotal(cart)
    localStorage.removeItem('click')
    setDataUser({
      ...dataUser,
      partner_id: partner.partner_id,
      partner_type_id: partner.partner_type_id,
      partner_name: partner.name,
    })

    localStorage.setItem('data-user', JSON.stringify(dataUser))
  }, [])

  useEffect(() => {
    if (dataUser.recipient_name) {
      if (!validators.lettersRegex.test(dataUser.recipient_name)) {
        setErrorName(
          'Nama tidak valid. Nama tidak boleh mengandung unsur non-alfabet.'
        )
      } else {
        setErrorName(false)
      }
    }

    if (dataUser.recipient_phone) {
      if (!validators.phoneRegex.test(dataUser.recipient_phone)) {
        setErrorPhone(
          'Harap masukkan format nomor yang benar dan angka terdiri dari 10-12 digit.'
        )
      } else {
        setErrorPhone(false)
      }
    }

    if (dataUser.pic_email && !validators.emailRegex.test(dataUser.pic_email)) {
      setErrorEmail('Harap masukkan format email yang benar.')
    } else {
      setErrorEmail(false)
    }

    if (
      errorPayment !== false &&
      dataUser.payment_bank.length >= 2 &&
      dataUser.payment_type !== ''
    ) {
      setErrorPayment(false)
      setError(false)
    }

    if (!errorName && !errorPhone && !errorEmail && !errorPayment)
      setError(false)
    localStorage.setItem('data-user', JSON.stringify(dataUser))
  }, [dataUser, errorName, errorPhone, errorEmail, errorPayment])

  if (userProfile?.isLoading)
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Loading />
      </div>
    )

  if (!userProfile?.isLoading)
    return (
      <React.Fragment>
        <MicrositePaymentLayout
          header="Info Kirim & Bayar"
          headForm="Informasi Pengiriman dan Pembayaran"
          descriptionForm="Silakan isi informasi dengan lengkap"
        >
          <div className="container border-b">
            <div className="grid grid-cols-5 md:py-6 pb-10 gap-11">
              <div className="col-span-5 md:col-span-3 flex flex-col gap-6">
                <CustomerDetail
                  dataUser={userProfile}
                  user={dataUser}
                  setUser={setDataUser}
                  errorEmail={errorEmail}
                  setErrorEmail={setErrorEmail}
                />
                <RecipientDetail
                  errorName={errorName}
                  errorPhone={errorPhone}
                  setErrorName={setErrorName}
                  setErrorPhone={setErrorPhone}
                  nama={nama}
                  setNama={setNama}
                  phone={phone}
                  setPhone={setPhone}
                  deliver={deliver}
                  setDeliver={setDeliver}
                  dataUser={dataUser}
                  setDataUser={setDataUser}
                  userProfile={userProfile}
                />
                <RequestForm user={dataUser} setUser={setDataUser} />
                <PaymentDetail
                  user={dataUser}
                  setUser={setDataUser}
                  errorPayment={errorPayment}
                  setErrorPayment={setErrorPayment}
                />
                <div className="fixed md:static block w-full left-0 px-4 md:h-28 bottom-0 shadow-top-only py-4 md:py-0 bg-white z-[53] md:bg-transparent md:shadow-none">
                  <button
                    onClick={() => handleSubmit()}
                    className="flex w-full bg-primary text-white justify-center md:mt-8 hover:opacity-80 text-sm font-semibold py-3 rounded-full"
                  >
                    Selanjutnya
                  </button>
                  {error && (
                    <p className="text-primary text-[9px] md:text-[11px] text-center pt-3">
                      Terdapat data yang belum terisi atau format belum sesuai.
                      Silakan cek kembali. (informasi penerima atau pembayaran)
                    </p>
                  )}
                </div>
              </div>
              <div className="col-span-5 md:col-span-2">
                <div className="hidden md:block">
                  <CartDetail
                    cart={cart}
                    partner={partner}
                    addProduct={addProduct}
                    updateQuantity={updateQuantity}
                    removeProduct={removeProduct}
                    grandTotal={grandTotal}
                    setDateParams={setDateParams}
                    changeDateCart={changeDateCart}
                    changeTimeCart={changeTimeCart}
                  />
                </div>
                <div className="block md:hidden mb-32">
                  <CartResponsive partner={partner} />
                </div>
              </div>
            </div>
          </div>
        </MicrositePaymentLayout>
      </React.Fragment>
    )
}

export default MicrositePaymentInfo
